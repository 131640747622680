import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Homepage({ staticContext, match }) {
  let initialData = {};
  if (staticContext && staticContext.data) {
    initialData = staticContext.data;
  }

  const [, setState] = useState(initialData);
  useEffect(() => {
    if (window.__ROUTE_DATA__) {
      setState(window.__ROUTE_DATA__);
      delete window.__ROUTE_DATA__;
    } else {
      const callback = require('@Callbacks/apiTestResults');
      setState(callback({}, match));
    }
  }, [match]);

  // Load the routes for the specific country.
  const routesCore = require('../../../config/core/client.routes.json');
  const routesOpCo = require(`../../../config/${process.env.REACT_APP_COUNTRY}/client.routes.json`);
  const routes = {
    ...routesCore,
    ...routesOpCo,
  };
  const lang = process.env.REACT_APP_DEFAULT_LANGUAGE;

  return (
    <div>
      <div><Link to={routes[lang].workforce360.url.replace(':searchParams*', '')}>Workforce360</Link></div>
    </div>
  );
}

export default Homepage;
